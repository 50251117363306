import { getFromServerAPI, postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { CauseStatuses } from "../enums/CauseStatuses"
import { ImpactStatuses } from "../enums/ImpactStatuses"
import { WellbeingStatuses } from '../../../@Common/Enums/WellbeingStatuses';
import { Duration } from '../../../@Common/Enums/Duration';
import { ConversationWriteModel } from '../models/ConversationWriteModel';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/Conversation";

const ADD_CONVERSATION = API_URL + "/AddConversation";
const GET_CONVERSATIONS_FOR_MHFA = API_URL + "/GetMHFAConversationMinifiedList";
const GET_CONVERSATION = API_URL + "/GetConversation";

export const saveNewConversation = async (conversation: ConversationWriteModel) => {
    let response = await postToServerAPI<ServiceResponse<any>>(ADD_CONVERSATION, conversation);
    return response;
}

export const getPreviousConversationIds = () => {
    return [
        { value: 1, label: '1' },
        { value: 2, label: '2' }
    ];
}

export const getMHFAConversations = async () => {
    let response = await postToServerAPI<ServiceResponse>(GET_CONVERSATIONS_FOR_MHFA);
    return response;
}

export const getMHFAConversationById = async (id: number) => {
    let response = await postToServerAPI<ServiceResponse>(GET_CONVERSATION, {'ConversationID': id});
    return response;
}

export const getCauseTypes = () => {
    return [
        { value: CauseStatuses.Money, label: 'Money' },
        { value: CauseStatuses.Sleep, label: 'Sleep' },
        { value: CauseStatuses.CaringResponsibilities, label: 'Caring responsibilities' },
        { value: CauseStatuses.Workload, label: 'Workload' },
        { value: CauseStatuses.Childcare, label: 'Childcare' },
        { value: CauseStatuses.Housing, label: 'Housing' },
        { value: CauseStatuses.PersonalPhysicalHealth, label: 'Personal physical health' },
        { value: CauseStatuses.LossSignificantChange, label: 'Loss, significant change' },
        { value: CauseStatuses.Bereavement, label: 'Bereavement' },
        { value: CauseStatuses.AgeingRelatives, label: 'Ageing relatives' },
        { value: CauseStatuses.ManagerRelationships, label: 'Manager relationships' },
        { value: CauseStatuses.ColleagueRelationships, label: 'Colleague relationships' },
        { value: CauseStatuses.RomanticRelationships, label: 'Romantic relationships' },
        { value: CauseStatuses.Menopause, label: 'Menopause' },
        { value: CauseStatuses.FamilyFriendMentalHealth, label: 'Family friend mental health' },
        { value: CauseStatuses.Other, label: 'Other' },
        { value: CauseStatuses.DontKnowTheCause, label: 'Don\'t know the cause' }
    ];
}

export const getIndividuallyExclusiveCauseTypes = () => {
    return [ CauseStatuses.DontKnowTheCause ];
}

export const getImpactTypes = () => {
    return [
        { value: ImpactStatuses.LowMood, label: 'Low mood', },
        { value: ImpactStatuses.Anxiety, label: 'Anxiety' },
        { value: ImpactStatuses.StressWork, label: 'Stress - work' },
        { value: ImpactStatuses.StressPersonal, label: 'Stress - personal' },
        { value: ImpactStatuses.Isolation, label: 'Isolation' },
        { value: ImpactStatuses.Loneliness, label: 'Loneliness' },
        { value: ImpactStatuses.SelfHarm, label: 'Self harm' },
        { value: ImpactStatuses.SuicidalThoughts, label: 'Suicidal thoughts' },
        { value: ImpactStatuses.FlatMood, label: 'Flat mood (numb)' },
        { value: ImpactStatuses.EatingDisorder, label: 'Eating disorder' },
        { value: ImpactStatuses.ChangesInThinking, label: 'Changes in thinking' },
        { value: ImpactStatuses.SubstanceMisuse, label: 'Substance misuse' }
    ];
}

export const getWellBeingTypes = () => {
    return [
        { value: WellbeingStatuses.VeryBad, label: 'Very Bad' },
        { value: WellbeingStatuses.Bad, label: 'Bad' },
        { value: WellbeingStatuses.Normal, label: 'Normal' },
        { value: WellbeingStatuses.Good, label: 'Good' },
        { value: WellbeingStatuses.VeryGood, label: 'Very Good' },
    ];
}

export const getDurationValues = () => {
    return [
        { value: Duration.LessThan15Mins, label: '5 - 15 Mins' },
        { value: Duration.Between15Minsand30Mins, label: '15 - 30 Mins' },
        { value: Duration.Between30Minsand60Mins, label: '30 - 1 Hour' },
        { value: Duration.LongerThan60Mins, label: '1 Hour +' }
    ];
}